import React, { useEffect } from 'react'
import { FormikProps } from 'formik'
import { useRouter } from 'next/router'
import classnames from 'classnames'

import Form from 'components/Form'
import Button from 'components/Button'
import FormikWrapper from 'components/FormikWrapper'

import AccountDetailsFields from '../AccountDetailsFields'
import AccountAddressFields from '../AccountAddressFields'
import BusinessDetailsFields from '../BusinessDetailsFields'
import SocialMediaDetailsFields from '../SocialMediaDetailsFields'
import useCreateBuyerForm, { CreateBuyerFormArguments } from './useCreateBuyerForm'
import { CheckAccountDetails_accountVerification } from '../SearchForBusinessNumberForm/graphql/__generated__/CheckAccountDetails'
import { BuyerAccountFragment } from './graphql/__generated__/BuyerAccountFragment'
import { CreateBuyerAccountMutation_createBuyer_buyer } from './graphql/__generated__/CreateBuyerAccountMutation'
import { AccountableTypeEnum } from '../../../../../__generated__/globalTypes'

import styles from './CreateBuyerForm.module.css'

type CreateBuyerFormProps = {
  className?: string
  onGoBack?: () => void
  onSuccess: (newBuyer: CreateBuyerAccountMutation_createBuyer_buyer) => Promise<void>
  businessData: CheckAccountDetails_accountVerification
  initialFieldValues?: BuyerAccountFragment
  defaultCountry?: string
  submitButton?: (formikBag: FormikProps<CreateBuyerFormArguments>) => React.ReactNode
  showBackButton?: boolean
  showEditBusinessDetailsButton?: boolean
  readOnly?: boolean
  hideBuyerFieldsets?: {
    businessDetails?: boolean
    accountDetails?: boolean
    addressDetails?: boolean
    socialMediaDetails?: boolean
  }
}

const CreateBuyerForm = ({
  className,
  onSuccess,
  businessData,
  onGoBack,
  initialFieldValues,
  defaultCountry,
  submitButton,
  showBackButton = true,
  showEditBusinessDetailsButton = false,
  readOnly = false,
  hideBuyerFieldsets = {
    businessDetails: false,
    accountDetails: false,
    addressDetails: false,
    socialMediaDetails: false
  }
}: CreateBuyerFormProps) => {
  const router = useRouter()

  const { initialValues, validationSchema, onSubmit } = useCreateBuyerForm({
    onSuccess,
    businessData,
    initialFieldValues,
    hideBuyerFieldsets
  })

  const disableFieldsExceptIfUS = defaultCountry === 'US' ? 'taxNumber' : true

  useEffect(() => {
    /*
     * Scroll position becomes stuck at the bottom of the page after submit on Business Number selection step.
     * This will reset the scroll position back to the top of the page.
     */
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <FormikWrapper
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formikHelpers) => {
        onSubmit(values, formikHelpers)
      }}>
      {formikBag => {
        const { isSubmitting, submitForm } = formikBag

        return (
          <Form disabled={readOnly}>
            <div className={classnames(styles.formContainer, className)}>
              {!hideBuyerFieldsets.businessDetails && (
                <>
                  <BusinessDetailsFields
                    disableFields={disableFieldsExceptIfUS}
                    className={styles.fieldset}
                    taxName={businessData.taxName ?? ''}
                    showEditButton={showEditBusinessDetailsButton}
                  />
                  <div className={styles.divider} />
                </>
              )}

              {!hideBuyerFieldsets.accountDetails && (
                <>
                  <AccountDetailsFields
                    className={styles.fieldset}
                    accountType={AccountableTypeEnum.BUYER}
                    defaultCountry={defaultCountry}
                    isOnboarding
                  />
                  <div className={styles.divider} />
                </>
              )}

              {!hideBuyerFieldsets.addressDetails && (
                <>
                  <AccountAddressFields
                    className={styles.fieldset}
                    accountType={AccountableTypeEnum.BUYER}
                    defaultCountry={defaultCountry}
                    isOnboarding
                  />
                  <div className={styles.divider} />
                </>
              )}

              {!hideBuyerFieldsets.socialMediaDetails && (
                <>
                  <SocialMediaDetailsFields
                    className={styles.fieldset}
                    subtitle="Adding your website and social media accounts helps to build trust and can help your wholesale pricing requests to be approved more quickly."
                  />
                </>
              )}

              <div className={styles.buttonSection}>
                {showBackButton && (
                  <Button
                    icon="arrow-left"
                    iconPosition="left"
                    onClick={() => (onGoBack ? onGoBack() : router.back())}
                    className={styles.backButton}
                  />
                )}

                {submitButton && submitButton(formikBag)}
                {!submitButton && (
                  <Button kind="primary" onClick={submitForm} disabled={isSubmitting} isSubmitting={isSubmitting}>
                    {initialFieldValues?.id ? 'Update' : 'Create'} Buyer account
                  </Button>
                )}
              </div>
            </div>
          </Form>
        )
      }}
    </FormikWrapper>
  )
}

export default CreateBuyerForm
