import * as Yup from 'yup'
import { useCallback, useMemo } from 'react'
import { FormikHelpers } from 'formik'

import {
  CreateBuyerAccountMutation,
  CreateBuyerAccountMutationVariables,
  CreateBuyerAccountMutation_createBuyer_buyer
} from './graphql/__generated__/CreateBuyerAccountMutation'
import { useToast } from 'components/Toast'
import { useMutation } from 'hooks/useMutation'
import formatTaxNumber from 'lib/format-tax-number'
import joinErrorPaths from 'lib/error-handling/join-error-paths'
import {
  AddressAttributesArguments,
  BuyerAttributesArguments,
  TaxNumberCheckStatusEnum
} from '../../../../../__generated__/globalTypes'
import CREATE_BUYER_ACCOUNT_MUTATION from './graphql/CreateBuyerAccountMutation.graphql'
import UPDATE_BUYER_ACCOUNT_MUTATION from './graphql/UpdateBuyerAccountMutation.graphql'
import {
  UpdateBuyerAccountMutation,
  UpdateBuyerAccountMutationVariables
} from './graphql/__generated__/UpdateBuyerAccountMutation'
import { CheckAccountDetails_accountVerification } from '../SearchForBusinessNumberForm/graphql/__generated__/CheckAccountDetails'
import { socialMediaDetailsAttributesValidation } from 'modules/signup/components/CreateSellerForm/useCreateSellerForm'
import { BuyerAccountFragment } from './graphql/__generated__/BuyerAccountFragment'

interface UseCreateBuyerFormProps {
  onSuccess: (buyer: CreateBuyerAccountMutation_createBuyer_buyer) => void
  businessData: CheckAccountDetails_accountVerification
  initialFieldValues?: BuyerAccountFragment
  hideBuyerFieldsets: {
    businessDetails?: boolean
    accountDetails?: boolean
    addressDetails?: boolean
    socialMediaDetails?: boolean
  }
}

export type AdditionalAddressAttributes = Pick<
  AddressAttributesArguments,
  'name' | 'contact' | 'locationType' | 'nickname' | 'email' | 'phone'
>

export type CreateBuyerFormArguments = BuyerAttributesArguments & {
  additionalShippingAddressAttributes: AdditionalAddressAttributes
  additionalBillingAddressAttributes: AdditionalAddressAttributes
  usesShippingForBillingAddress: boolean
}

const useCreateBuyerForm = ({
  onSuccess,
  businessData,
  initialFieldValues,
  hideBuyerFieldsets
}: UseCreateBuyerFormProps) => {
  const [showToast] = useToast()

  const addressAttributeValidation = useMemo(() => {
    return Yup.object().shape({
      id: Yup.string().optional(),
      street1: Yup.string().max(191).optional(),
      street2: Yup.string().max(191).optional().nullable(),
      city: Yup.string().max(191).required(),
      state: Yup.string().max(191).required(),
      country: Yup.string().max(191).required(),
      postcode: Yup.string().max(191).required()
    })
  }, [])

  const additionalAddressAttributesValidation = Yup.object().shape({
    name: Yup.string().max(191).nullable().required('Required'),
    contact: Yup.string().max(191).nullable().required('Required'),
    locationType: Yup.string().nullable(),
    nickname: Yup.string().nullable(),
    email: Yup.string().email().max(191).nullable().required('Required'),
    phone: Yup.string().max(191).nullable().required('Required')
  })

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      // Make required, only if businessDetails is shown and taxNumber is empty
      taxNumber: Yup.string().test('required', 'Required', function (taxNumber) {
        if (Boolean(hideBuyerFieldsets.businessDetails === false) && !taxNumber) {
          return this.createError({ message: 'Required', path: this.path })
        }
        return true
      }),
      entityName: Yup.string().nullable().required('Required'),
      displayName: Yup.string().required('Required'),
      description: Yup.string().nullable().required('Required'),
      // Make required, only if
      // - accountDetails is shown,
      // - businessCategoryTaxonomyId is empty
      businessCategoryTaxonomyId: Yup.string()
        .nullable()
        .test('required', 'Required', function (id) {
          if (Boolean(hideBuyerFieldsets.accountDetails) === false && !id) {
            return this.createError({ message: 'Required', path: this.path })
          }
          return true
        }),
      email: Yup.string().required('Required'),
      phone: Yup.string().required('Required'),
      shippingAddressAttributes: addressAttributeValidation,
      billingAddressAttributes: addressAttributeValidation.nullable(),
      socialMediaDetailsAttributes: socialMediaDetailsAttributesValidation,
      usesShippingForBillingAddress: Yup.boolean(),
      additionalShippingAddressAttributes: additionalAddressAttributesValidation,
      additionalBillingAddressAttributes: additionalAddressAttributesValidation
    })
  }, [
    additionalAddressAttributesValidation,
    addressAttributeValidation,
    hideBuyerFieldsets.accountDetails,
    hideBuyerFieldsets.businessDetails
  ])

  const additionalShippingAddressAttributesInitialValues: AdditionalAddressAttributes = {
    name: initialFieldValues?.shippingAddress?.name ?? initialFieldValues?.displayName ?? null,
    contact: initialFieldValues?.shippingAddress?.contact ?? null,
    locationType: initialFieldValues?.shippingAddress?.locationType ?? undefined,
    nickname: initialFieldValues?.shippingAddress?.nickname ?? null,
    email: initialFieldValues?.shippingAddress?.email ?? null,
    phone: initialFieldValues?.shippingAddress?.phone ?? null
  }

  const additionalBillingAddressAttributesInitialValues: AdditionalAddressAttributes = {
    name: initialFieldValues?.billingAddress?.name ?? initialFieldValues?.displayName ?? null,
    contact: initialFieldValues?.billingAddress?.contact ?? null,
    locationType: initialFieldValues?.billingAddress?.locationType ?? undefined,
    nickname: initialFieldValues?.billingAddress?.nickname ?? null,
    email: initialFieldValues?.billingAddress?.email ?? null,
    phone: initialFieldValues?.billingAddress?.phone ?? null
  }

  const initialValues: CreateBuyerFormArguments = {
    taxNumber: formatTaxNumber({
      taxNumber: businessData.taxNumber ?? initialFieldValues?.taxNumber ?? '',
      country: initialFieldValues?.billingAddress?.country ?? ''
    }),
    entityName: (businessData.businessName || initialFieldValues?.entityName) ?? '',
    displayName: initialFieldValues?.displayName ?? '',
    description: initialFieldValues?.description ?? '',
    businessCategoryTaxonomyId: initialFieldValues?.businessCategoryTaxonomyId ?? '',
    email: initialFieldValues?.email ?? '',
    phone: initialFieldValues?.phone ?? '',
    usesShippingForBillingAddress: initialFieldValues?.billingAddressId === initialFieldValues?.shippingAddressId,
    additionalShippingAddressAttributes: additionalShippingAddressAttributesInitialValues,
    additionalBillingAddressAttributes: additionalBillingAddressAttributesInitialValues,
    shippingAddressAttributes: {
      id: initialFieldValues?.shippingAddress?.id ?? undefined,
      street1: initialFieldValues?.shippingAddress?.street1 ?? '',
      street2: initialFieldValues?.shippingAddress?.street2 ?? '',
      city: initialFieldValues?.shippingAddress?.city ?? '',
      state: initialFieldValues?.shippingAddress?.state ?? '',
      postcode: initialFieldValues?.shippingAddress?.postcode ?? '',
      country: initialFieldValues?.shippingAddress?.country ?? ''
    },
    billingAddressAttributes: {
      id: initialFieldValues?.billingAddress?.id ?? undefined,
      street1: initialFieldValues?.billingAddress?.street1 ?? '',
      street2: initialFieldValues?.billingAddress?.street2 ?? '',
      city: initialFieldValues?.billingAddress?.city ?? '',
      state: initialFieldValues?.billingAddress?.state ?? '',
      postcode: initialFieldValues?.billingAddress?.postcode ?? '',
      country: initialFieldValues?.billingAddress?.country ?? ''
    },
    socialMediaDetailsAttributes: {
      id: initialFieldValues?.socialMediaDetails?.id ?? undefined,
      websiteUrl: initialFieldValues?.socialMediaDetails?.websiteUrl ?? '',
      instagramUsername: initialFieldValues?.socialMediaDetails?.instagramUsername ?? '',
      twitterUsername: initialFieldValues?.socialMediaDetails?.twitterUsername ?? '',
      facebookUrl: initialFieldValues?.socialMediaDetails?.facebookUrl ?? ''
    }
  }

  const [createBuyer] = useMutation<CreateBuyerAccountMutation, CreateBuyerAccountMutationVariables>(
    CREATE_BUYER_ACCOUNT_MUTATION,
    {
      context: { hasUpload: true },
      onCompleted: data => {
        if (data?.createBuyer?.success && data.createBuyer.buyer) {
          onSuccess && onSuccess(data.createBuyer.buyer)
        }
      },
      onError: () => showToast({ kind: 'error', message: 'Failed to create buyer' })
    }
  )

  const [updateBuyer] = useMutation<UpdateBuyerAccountMutation, UpdateBuyerAccountMutationVariables>(
    UPDATE_BUYER_ACCOUNT_MUTATION,
    {
      context: { hasUpload: true },
      onCompleted: data => {
        if (data?.updateBuyer?.success && data.updateBuyer.buyer) {
          onSuccess && onSuccess(data.updateBuyer.buyer)
        }
      },
      onError: () => showToast({ kind: 'error', message: 'Failed to update buyer' })
    }
  )

  const onSubmit = useCallback(
    async (
      values: CreateBuyerFormArguments,
      { setSubmitting, setFieldError }: FormikHelpers<CreateBuyerFormArguments>
    ) => {
      setSubmitting(true)

      values.shippingAddressAttributes = {
        ...values.shippingAddressAttributes,
        ...values.additionalShippingAddressAttributes
      } as AddressAttributesArguments

      if (values.usesShippingForBillingAddress) {
        values.billingAddressAttributes = {
          ...values.shippingAddressAttributes
        } as AddressAttributesArguments
      } else {
        values.billingAddressAttributes = {
          ...values.billingAddressAttributes,
          ...values.additionalBillingAddressAttributes
        } as AddressAttributesArguments
      }

      // NOTE: Need to destructure object without removing `additionalBillingAddressAttributes`, `additionalShippingAddressAttributes` and `usesShippingForBillingAddress` since they are needed for form validation.
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        usesShippingForBillingAddress,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        additionalBillingAddressAttributes,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        additionalShippingAddressAttributes,
        ...buyerAttributes
      } = values

      let result, errors
      if (initialFieldValues?.id) {
        result = await updateBuyer({
          variables: {
            id: initialFieldValues.id,
            attributes: {
              ...buyerAttributes,
              taxNumberCheckStatus: TaxNumberCheckStatusEnum.VERIFIED
            }
          }
        })
        errors = joinErrorPaths(result?.data?.updateBuyer?.errors)
      } else {
        result = await createBuyer({
          variables: {
            attributes: {
              ...buyerAttributes,
              taxNumberCheckStatus: TaxNumberCheckStatusEnum.VERIFIED
            }
          }
        })

        errors = joinErrorPaths(result?.data?.createBuyer?.errors)
      }
      errors?.forEach(e => (e.path && e.message ? setFieldError(e.path, e.message) : ''))
      setSubmitting(false)
    },
    [createBuyer, initialFieldValues?.id, updateBuyer]
  )

  return {
    onSubmit,
    initialValues,
    validationSchema
  }
}

export default useCreateBuyerForm
